<template>
  <div class="container mt-5 pt-5">
    <about-us />
  </div>
</template>

<script>
import AboutUs from "../components/about-us/AboutUs.vue";
export default {
  components: { AboutUs },
};
</script>

<style></style>
